import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';
import 'moment/locale/es-mx';
import React, { useCallback, useEffect, useState } from 'react';
import { createTask, getTasksPath } from '../../../utils/tasks';
import { createStory } from '../../../utils/stories';
import { fetchTaskInfo, getDuration, getUserId, STATES } from '../../../utils/utils';
import ApiAutocomplete from '../Autocomplete/ApiAutocomplete';
import ArrayAutocomplete from '../Autocomplete/ArrayAutocomplete';
import AutocompleteChipSection from '../Autocomplete/AutocompleteChipSection';
import { PrimaryButton, SecondaryButton } from '../Button';
import useFormValidation from '../hooks/useFormValidation';
import LoadEffortTime from '../LoadEffortTime';
import { TextInput } from '../TextInput';
import validate from './validate';

const INITIAL_STATE = {
  project: '',
  iteration: '',
  story: '',
  task: '',
  state: '',
  start: new Date(),
  end: new Date(),
  duration: 0,
  responsibles: [
    {
      id: getUserId(),
      fullName: 'You',
    },
  ],
  participants: [
    {
      id: getUserId(),
      fullName: 'You',
    },
  ],
  comments: '',
};

const LABELS = {
  project: 'Project',
  iteration: 'Iteration',
  story: 'Story',
  task: 'Task',
  state: 'State',
  start: 'Start',
  end: 'End',
  duration: 'Duration',
  responsibles: 'Responsibles',
  comments: 'Comments',
};

const useStyles = makeStyles(() => ({
  dialogTitle: { padding: '24px 16px 0px', textAlign: 'center' },
  dialogContent: { display: 'flex', flexDirection: 'column', paddingTop: 0 },
  warningText: { marginBottom: 0, color: '#ff9800', fontSize: 16 },
  endContainer: { display: 'flex' },
  dialogActions: { display: 'flex', justifyContent: 'space-between', padding: '16px 24px 24px' },
}));

const ManualLoadDialog = ({ open, handleClose, info, addEvent, editEvent }) => {
  const classes = useStyles();
  const { handleSubmit, handleChange, handleBlur, values, errors, resetValues, resetError, setErrors } =
    useFormValidation(INITIAL_STATE, v => validate(v));
  const [data, setData] = useState({}); //Data before changes
  const isNew = Boolean(info?.isNew);

  const handleChangeAutocomplete = useCallback(
    (name, value) => handleChange({ target: { name, value } }),
    [handleChange]
  );
  const handleChangeProject = useCallback(
    value => handleChangeAutocomplete('project', value),
    [handleChangeAutocomplete]
  );
  const handleChangeIteration = useCallback(
    value => handleChangeAutocomplete('iteration', value),
    [handleChangeAutocomplete]
  );
  const handleChangeStory = useCallback(value => handleChangeAutocomplete('story', value), [handleChangeAutocomplete]);
  const handleChangeTask = useCallback(value => handleChangeAutocomplete('task', value), [handleChangeAutocomplete]);
  const handleChangeState = useCallback(value => handleChangeAutocomplete('state', value), [handleChangeAutocomplete]);

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    return changedValues;
  };

  const handleResult = async e => {
    const formIsValid = handleSubmit(e);
    if (formIsValid) {
      const { project, iteration, story, task, state, start, end, comments, responsibles, participants } = values;
      const addEventForNewTask = taskId =>
        addEvent({
          start: start,
          end: end,
          name: task.name,
          project,
          iteration,
          story,
          id: taskId,
          comments,
          participants: participants,
        });

      if (isNew) {
        let tempStory;

        if (!story.id) {
          await createStory(project, iteration, state, responsibles, values.story.name).then(r => {
            tempStory = { id: r.message };
          });
        }

        const id = task.id;
        let finalStoryValue;
        if (!id) {
          if (tempStory) {
            finalStoryValue = tempStory;
          } else {
            finalStoryValue = story;
          }

          story
            ? createTask(project, iteration, finalStoryValue, task, state, responsibles)
                .then(addEventForNewTask)
                .then(handleClose)
            : createTask(project, iteration, tempStory, task, state, responsibles)
                .then(addEventForNewTask)
                .then(handleClose);
        } else {
          addEvent({
            start,
            end,
            name: task.name,
            project,
            iteration,
            story,
            id,
            comments,
            responsibles: data.responsibles !== responsibles ? responsibles : undefined,
            state: data.state !== state.id ? state : undefined,
            participants,
          });
        }
      } else {
        const changedValues = getChangedValues(values);
        if (changedValues.length === 0) {
          handleClose();
          return;
        }
        let eventChanges = {};
        changedValues.forEach(k => {
          if (k === 'duration') return;
          else if (k === 'start' || k === 'end') eventChanges = { ...eventChanges, [k]: values[k] };
          else
            eventChanges = {
              ...eventChanges,
              resource: { ...eventChanges.resource, [k]: values[k] },
            };
        });
        editEvent({ eventChanges, originalEvent: info });
      }
      handleClose();
    }
  };

  const updateStateAndResponsibles = useCallback(
    ({ state, responsibles }) => {
      const me = getUserId();
      const value = responsibles.map(r => ({
        id: r.id,
        fullName: r.id === me ? 'You' : r.fullName ?? 'Unknown',
      }));
      handleChangeState(state);
      handleChange({ target: { name: 'responsibles', value } });
      setData(prev => ({ ...prev, responsibles: value, state }));
    },
    [handleChange, handleChangeState]
  );

  useEffect(() => {
    if (!isNew) return;
    if (!values.task || !values.task.id) return;
    fetchTaskInfo(0, values.project.id, values.iteration.id, values.story.id, values.task.id)
      .then(updateStateAndResponsibles)
      .catch(handleChangeState(STATES[0]));
  }, [
    handleChangeState,
    isNew,
    values.project,
    values.iteration,
    values.story,
    values.task,
    handleChange,
    updateStateAndResponsibles,
  ]);

  useEffect(() => {
    if (!info || isNew || !info.resource) return;
    const { project, iteration, story, task } = info.resource;
    fetchTaskInfo(
      0, // Client
      project.id,
      iteration.id,
      story.id,
      task.id
    ).then(updateStateAndResponsibles);
  }, [handleChange, handleChangeState, info, isNew, updateStateAndResponsibles]);

  useEffect(() => {
    if (info) {
      const newValues = {
        ...INITIAL_STATE,
        ...info.resource,
        start: info.start,
        end: info.end,
        duration: getDuration(info.start, info.end),
      };
      setData(newValues);
      setErrors({});
      resetValues(newValues);
    }
  }, [setErrors, resetValues, info]);

  const customHandleAdd = useCallback(
    (field, value) =>
      ({ id, fullName }) =>
        handleChange({
          target: {
            name: field,
            value: [...value, { id, fullName: id === getUserId() ? 'You' : fullName }],
          },
        }),
    [handleChange]
  );

  const handleDeleteChip = useCallback(
    (field, id) => {
      return handleChange({
        target: {
          name: field,
          value: values[field].filter(v => v.id !== id),
        },
      });
    },
    [handleChange, values]
  );

  const customHandleAddParticipants = useCallback(
    ({ id, fullName }) => {
      const responsiblesId = values.responsibles.map(r => r.id);
      if (!responsiblesId.includes(id)) {
        customHandleAdd('responsibles', values.responsibles)({ id, fullName });
      }
      return customHandleAdd('participants', values.participants)({ id, fullName });
    },
    [customHandleAdd, values.participants, values.responsibles]
  );

  const customHandleDeleteResponsibles = useCallback(
    ({ id, fullName }) => {
      const participantsId = values.participants.map(p => p.id);
      if (participantsId.includes(id) && isNew) {
        handleDeleteChip('participants', id);
      }
      return handleDeleteChip('responsibles', id);
    },
    [handleDeleteChip, isNew, values.participants]
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>{isNew ? 'Load effort' : 'Edit effort'}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography
          style={{
            textAlign: 'center',
            marginTop: '16px',
            marginBottom: '-28px',
          }}
        >
          Task Info
        </Typography>
        <ApiAutocomplete
          path="projects"
          field="name"
          value={values.project}
          label={LABELS.project}
          handleChange={handleChangeProject}
          error={errors.project}
          disabled={!isNew}
        />
        <ApiAutocomplete
          path={`clients/0/projects/${values.project.id}/iterations`}
          field="name"
          value={values.iteration}
          label={LABELS.iteration}
          handleChange={handleChangeIteration}
          error={errors.iteration}
          disabled={!isNew || !values.project}
          dependsOn={values.project}
        />
        <ApiAutocomplete /* Storie's autocomplete */
          path={`clients/0/projects/${values.project.id}/iterations/${values.iteration.id}/stories`}
          field="name"
          value={values.story}
          label={LABELS.story}
          handleChange={handleChangeStory}
          error={errors.story}
          disabled={!isNew || !values.iteration}
          dependsOn={values.iteration}
          createText="Create story"
          freeSolo
        />

        <ApiAutocomplete /* Task's autocomplete */
          path={getTasksPath(values)}
          field="name"
          value={values.task}
          label={LABELS.task}
          handleChange={handleChangeTask}
          error={errors.task}
          disabled={!isNew || !values.iteration}
          freeSolo
          dependsOn={values.iteration}
          createText="Create the task"
        />
        <ArrayAutocomplete
          options={STATES}
          error={errors.state}
          label={LABELS.state}
          value={values.state}
          handleChange={handleChangeState}
          field="name"
          useFieldInTextField
          dependsOn={values.task}
          disabled={!values.task}
        />
        <AutocompleteChipSection
          editMode
          path={'users'}
          apiField={'fullName'}
          idField={'id'}
          field="responsibles"
          value={values.task ? values.responsibles ?? [] : []}
          handleChange={handleChange}
          handleBlur={handleBlur}
          helpText={'Add more people to this task so it will appear in their Quick Load Dialog'}
          label={'Add more responsibles to this task'}
          dependsOn={values.task}
          disabled={!values.task}
          customHandleAdd={customHandleAdd('responsibles', values.responsibles)}
          customHandleDelete={customHandleDeleteResponsibles}
          error={errors.responsibles}
        />
        <Typography
          style={{
            textAlign: 'center',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          Effort Info
        </Typography>
        <LoadEffortTime
          values={values}
          errors={errors}
          handleChange={handleChange}
          resetError={resetError}
          handleBlur={handleBlur}
          info={info}
        >
          {isNew && (
            <AutocompleteChipSection
              editMode={isNew}
              path={'users'}
              apiField={'fullName'}
              field="participants"
              value={values.task ? values.participants ?? [] : []}
              handleChange={handleChange}
              handleBlur={handleBlur}
              helpText={'Load effort for another people by typing their name'}
              label={'Add more people to this effort'}
              customHandleAdd={customHandleAddParticipants}
              disabled={!values.task}
              error={errors.participants}
              noItemsText={'No people found'}
            />
          )}
        </LoadEffortTime>

        <TextInput
          style={{
            marginTop: isNew ? '16px' : '0',
          }}
          multiline
          maxRows={3}
          name="comments"
          value={values.comments}
          error={errors.comments}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label="Description"
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={handleResult}>Save</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ManualLoadDialog;
