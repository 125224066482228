import { handleResponse, post } from './network';

export const createStory = async (project, iteration, state, responsibles, name) => {
  const query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories`;

  const body = {
    state: state.id ?? 0,
    responsibles: responsibles.map(r => r.id),
    name: name,
  };

  const res = await post(query, body);

  const hr = await handleResponse(res);

  return hr;
};
